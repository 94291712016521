/* Navbar.css */
.navbar {
  display: flex;
  /* justify-content: space-between; */
  align-items: left;
  background-color: #001529; /* Default AntD dark theme */
}

.logo {
  float: left;
  width: 100px;
  height: 50px;
  margin: 5px 200px 20px 10px;
  /* display: flex; */
  align-items: center;
}

.logo img {
  max-height: 100%;
  max-width: 100%;
}
.carousol {
  height: '160px';
  color: '#fff';
  lineHeight: '160px';
  textAlign: 'center';
  background: '#364d79';
};

.customfont{
font-family:'Qwitcher Grypen'
};

.ant-carousel .slick-dots li button {
  background-color: #a10a0a; /* Inactive dot color */
  height: 8px; /* Corrected: no quotes */
  width: 8px; /* Optional: to make the dot round */
  opacity: 1 !important; /* Force the opacity to be 1 */
}

/* Change the color of the active dot */
.ant-carousel .slick-dots li.slick-active button {
  background-color: #00488b; /* Active dot color */
  height: 8px;
}